@use "breakpoints";
@use "buttons";
@use "colors";
@use "layout";
@use "typography";
@import "custom";

.hero-text {
  @include layout.module-content;

  justify-content: center;

  &[data-background-shade="true"] {
    &::after {
      background-color: rgba(0, 0, 0, 50%);
      content: "";
      inset: 0;
      position: absolute;
      z-index: 1;
    }
  }
}

.headline {
  @include typography.xl-headline;

  color: colors.$white;
  margin-block: auto;
  white-space: pre-wrap;
  z-index: 2;

  &:has(+ *) {
    margin-block-end: 4rem;
  }

  @media (min-width: breakpoints.$lg) {
    margin-block-start: 12.4rem;
  }
}

.hero-text[data-compact="true"] {
  padding-block: 4rem;

  .headline {
    margin-block-start: auto;
  }
}

.subheading {
  @include typography.subhead-regular;

  color: colors.$white;
  margin-block-end: auto;
  white-space: pre-wrap;
  z-index: 2;

  &:has(+ *) {
    margin-block-end: 4rem;
  }
}

.cta {
  flex-basis: 0%;
  margin-block-end: auto;
}

.scroll-to-next-section {
  background-color: transparent;
  border: 1px solid colors.$light-gray;
  border-radius: 100px;
  color: #f8f9fa;
  display: none;
  margin-block-end: 2.4rem;
  max-inline-size: 43px;
  padding: 0;
  user-select: none;
  z-index: 2;

  &:hover {
    border-color: colors.$white;
    color: colors.$white;
  }

  @media (min-width: breakpoints.$lg) {
    display: block;
  }
}

/**********************************************************************
 * old headline
 */

.header {
  color: colors.$white;
  padding-block-start: 10%;
  padding-inline: 0;

  @media (width >= breakpoints.$xs) {
    padding-block-start: 5%;
  }

  @media (width >= breakpoints.$sm) {
    padding-block-start: 2%;
  }
}

.headline-element-desktop {
  @include typography.xl-headline;

  margin: 15px 0 20px;
}

.headline-element-mobile {
  @include typography.xl-headline;

  margin: 15px 0 20px;

  @include media-breakpoint-up(md) {
    margin: 16px 0 20px;
  }
}

.subcopy {
  @include typography.subhead-regular;

  color: colors.$white;
}
